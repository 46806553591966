import Vue from "vue";
import VueRouter from 'vue-router'

const Home = () => import(/* webpackChunkName: "home-view" */'./views/HomeView.vue')

const Category = () => import(/* webpackChunkName: "category-view" */'./views/CategoryView.vue')

const Product = () => import(/* webpackChunkName: "product-view" */'./views/ProductView.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/category/:id",
    name: "category",
    component: Category
  },
  {
    path: '/product/:category/:id',
    name: 'product',
    component: Product
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;