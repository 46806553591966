<template>
  <v-app>
    <app-toolbar />
    <v-main>
      <router-view/>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>
<script>
export default {
  name: 'MainApp',
  components: {
    AppToolbar: () => import(/* webpackChunkName: "toolbar" */'./components/Toolbar.vue'),
    AppFooter: () => import(/* webpackChunkName: "footer" */'./components/Footer.vue')
  }
};
</script>
<style>
</style>
